import React, { useEffect, useState } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import SpotForm from "@/forms/SpotForm";
import { Spot } from "@/Models";
import { useSpotsStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ id, onUpdate = _noop } = {}) => {
  const [initSpot, setInitSpot] = useState(null);
  const { ...actions } = useSpotsStore();
  const { closeModal } = useModalStore();

  useEffect(() => {
    actions.fetchByIdOnTheFly(id).then((spotResult) => {
      const spot = new Spot(spotResult);

      setInitSpot(spot);
    });
  }, []);

  const onSubmit = async ({
    address,
    addressDetail,
    autoDeliveryAllocation,
    autoPickupAllocationOnWeekends,
    code,
    codeFromCorp,
    contact,
    contactForLabel,
    deliveredSmsActive,
    realTimeAlimtalkAvailable,
    returnPickupAlimtalkAvailable,
    deliveryStartSmsActive,
    duplicatedByOrderIdFromCorp,
    estimatedTimeHour,
    fee,
    gatheringReference,
    memo,
    name,
    postalCode,
    returnEnabled,
    searchableByOrderIdFromCorp,
    postponedAlimtalkAvailable,
    kurlyDrink,
    luxuryAvailable,
  }) => {
    const data = {};

    if (address !== initSpot.address) data.address = address;
    if (addressDetail !== initSpot.addressDetail) {
      data.address = address;
      data.addressDetail = addressDetail;
    }
    if (autoDeliveryAllocation !== initSpot.autoDeliveryAllocation)
      data.autoDeliveryAllocation = autoDeliveryAllocation;
    if (
      autoPickupAllocationOnWeekends !== initSpot.autoPickupAllocationOnWeekends
    )
      data.autoPickupAllocationOnWeekends = autoPickupAllocationOnWeekends;
    if (code !== initSpot.code) data.code = code;
    if (codeFromCorp !== initSpot.codeFromCorp)
      data.codeFromCorp = codeFromCorp;
    if (contact !== initSpot.contact) data.contact = contact;
    if (contactForLabel !== initSpot.contactForLabel)
      data.contactForLabel = contactForLabel;
    if (deliveredSmsActive !== initSpot.deliveredSmsActive)
      data.deliveredSmsActive = deliveredSmsActive;
    if (realTimeAlimtalkAvailable !== initSpot.realTimeAlimtalkAvailable)
      data.realTimeAlimtalkAvailable = realTimeAlimtalkAvailable;
    if (
      returnPickupAlimtalkAvailable !== initSpot.returnPickupAlimtalkAvailable
    )
      data.returnPickupAlimtalkAvailable = returnPickupAlimtalkAvailable;
    if (deliveryStartSmsActive !== initSpot.deliveryStartSmsActive)
      data.deliveryStartSmsActive = deliveryStartSmsActive;
    if (duplicatedByOrderIdFromCorp !== initSpot.duplicatedByOrderIdFromCorp)
      data.duplicatedByOrderIdFromCorp = duplicatedByOrderIdFromCorp;
    if (Number(estimatedTimeHour) !== Number(initSpot.estimatedTimeHour)) {
      data.estimatedTimeHour = estimatedTimeHour || 8;
    }
    if (Number(fee) !== Number(initSpot.fee)) {
      data.fee = fee || 0;
    }
    if (gatheringReference !== initSpot.gatheringReference)
      data.gatheringReference = gatheringReference;
    if (
      (!!initSpot.memo && memo !== initSpot.memo) ||
      (!!memo && memo !== initSpot.memo)
    )
      data.memo = memo;
    if (name !== initSpot.name) data.name = name;
    if (postalCode !== initSpot.postalCode) data.postalCode = postalCode;
    if (returnEnabled !== initSpot.returnEnabled) {
      if (returnEnabled === "NONE") {
        data.returnActive = false;
      } else {
        data.returnActive = true;
      }
      data.returnEnabled = returnEnabled;
    }
    if (searchableByOrderIdFromCorp !== initSpot.searchableByOrderIdFromCorp) {
      data.searchableByOrderIdFromCorp = searchableByOrderIdFromCorp;
    }
    if (postponedAlimtalkAvailable !== initSpot.postponedAlimtalkAvailable) {
      data.postponedAlimtalkAvailable = postponedAlimtalkAvailable;
    }
    if (kurlyDrink !== initSpot.kurlyDrink) {
      data.kurlyDrink = kurlyDrink;
    }
    if (luxuryAvailable !== initSpot.luxuryAvailable) {
      data.luxuryAvailable = luxuryAvailable;
    }

    actions
      .update(id, data)
      .then(() => {
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(`수거지 수정에 실패했습니다: ${e.message}`);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>수거지 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <SpotForm onSubmit={onSubmit} initSpot={initSpot} />
      </Modal.Body>
    </Modal>
  );
};
